.loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.836);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
}

.loader > :first-child {
  margin: auto;
}
