.max-width-800 {
  max-width: 800px;
}

.slick-dots li.slick-active button:before,
.slick-dots li button:before {
  color: White !important;
}

.slick-dots {
  position: static !important;
}
