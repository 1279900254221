.Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 50px;
  margin: 16px auto;
  gap: 8px;
  border: none;
  max-width: 128px;
  box-shadow: 4px 4px 12px 2px rgba(0, 0, 0, 0.1);
  color: white;
  font-weight: 600;
  background-color: #d75252;
  border-radius: 50px;
  transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
  -moz-transition: background-color 0.3s 0.1s ease;
  -o-transition: background-color 0.3s 0.1s ease;
}

.Button:disabled {
  background-color: #716b6b;
}

.Button:disabled:hover {
  background-color: #716b6b;
}

.Button:hover {
  background: #b54545;
}

.Button__icon {
  color: white;
  height: 20px;
}

/*Button animation apparition*/
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation: fadeInUp ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}
