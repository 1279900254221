@import url("https://fonts.googleapis.com/css2?family=Kalam&display=swap");

.card {
  cursor: pointer;
  position: relative;
  color: black;
  box-shadow: 1px 6px 6px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 1px 6px 6px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 1px 6px 6px 1px rgba(0, 0, 0, 0.3);
  font-family: "Kalam", cursive;
  align-items: center;
  justify-items: center;
  margin: 12px;
  height: 370px;
}

.flex0 {
  flex: 0 !important;
}

.card > h1 {
  font-family: "Kalam", cursive;
}

/* Checked icon */
.label__check {
  display: inline-block;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.1);
  background: white;
  vertical-align: middle;
  margin-right: 20px;
  width: 2em;
  height: 2em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 0.3s ease;
}
.label__check i.icon {
  opacity: 0.2;
  font-size: calc(1rem + 1vw);
  color: transparent;
  transition: opacity 0.3s 0.1s ease;
  -webkit-text-stroke: 3px rgba(0, 0, 0, 0.5);
  -webkit-transition: opacity 0.3s 0.1s ease;
  -moz-transition: opacity 0.3s 0.1s ease;
  -o-transition: opacity 0.3s 0.1s ease;
}
.label__check:hover {
  border: 5px solid rgba(0, 0, 0, 0.2);
}
.checked .label__text .label__check {
  -webkit-animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
  animation: check 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) forwards;
}
.checked .label__text .label__check .icon {
  opacity: 1;
  transform: scale(0);
  color: white;
  -webkit-text-stroke: 0;
  -webkit-animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1
    forwards;
  animation: icon 0.3s cubic-bezier(1, 0.008, 0.565, 1.65) 0.1s 1 forwards;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Checked icon animation */
@-webkit-keyframes icon {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes icon {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes check {
  0% {
    width: 1.5em;
    height: 1.5em;
    border-width: 5px;
  }
  10% {
    width: 1.5em;
    height: 1.5em;
    opacity: 0.1;
    background: rgba(0, 0, 0, 0.2);
    border-width: 15px;
  }
  12% {
    width: 1.5em;
    height: 1.5em;
    opacity: 0.4;
    background: rgba(0, 0, 0, 0.1);
    border-width: 0;
  }
  50% {
    width: 2em;
    height: 2em;
    background: #83bc34;
    border: 0;
    opacity: 0.6;
  }
  100% {
    width: 2em;
    height: 2em;
    background: #83bc34;
    border: 0;
    opacity: 1;
  }
}
@keyframes check {
  0% {
    width: 1.5em;
    height: 1.5em;
    border-width: 5px;
  }
  10% {
    width: 1.5em;
    height: 1.5em;
    opacity: 0.1;
    background: rgba(0, 0, 0, 0.2);
    border-width: 15px;
  }
  12% {
    width: 1.5em;
    height: 1.5em;
    opacity: 0.4;
    background: rgba(0, 0, 0, 0.1);
    border-width: 0;
  }
  50% {
    width: 2em;
    height: 2em;
    background: #83bc34;
    border: 0;
    opacity: 0.6;
  }
  100% {
    width: 2em;
    height: 2em;
    background: #83bc34;
    border: 0;
    opacity: 1;
  }
}
