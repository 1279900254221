.App {
  text-align: center;
  background: #00362f;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.App::before {
  content: "";
  background-image: url("./assets/backChrismath.webp");
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.2;
  background-attachment: fixed;
}

.container--main {
  position: relative;
  color: #e4ded5;
  /* max-width: 1000px; */
}

li {
  list-style: none;
}

ul {
  padding: 0 !important;
}

.black {
  background-color: black;
  min-height: 100vh;
  color: white;
}

p {
  margin-bottom: 0 !important;
}
